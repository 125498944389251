import React from 'react'
import CaseStudyLayout, {
  Quote,
  StarDivider,
  DotsDivider,
  SliderDivider,
  CustomerPhoto,
  CastleHero,
  OtherCaseStudies,
} from '../../components/casestudy/caseStudyLayout'
import Seo from '../../components/seo'

import shareImage from '../../components/casestudy/images/castleShare.png'

const Castle = () => {
  return (
    <CaseStudyLayout
      hero={<CastleHero />}
      title="How Castle uses Probe to automate analytics"
    >
      <Seo
        title="Castle uses Probe to automate analytics"
        description={
          'Castle uses Probe to save time and automate their business analytics.'
        }
        keywords={[
          'stripe',
          'castle.io',
          'saas',
          'excel',
          'spreadsheets',
          'bi',
          'analytics',
        ]}
        twitterImg={shareImage}
        mainpageImg={shareImage}
      />
      <CustomerPhoto
        name="Valerie Kirk"
        title="Director of Business Operations"
        company="Castle"
        webPage="https://castle.io"
      />
      <div className="py-5">
        <h2>What do you do at Castle?</h2>
        <p>
          I am Director of Business Operations at Castle. I oversee the Finance
          function and Operations related to Sales Ops, HR, and Legal.
        </p>
      </div>
      <StarDivider />
      <div className="py-5">
        <h2>What’s the problem that Probe is solving for you?</h2>
        <p>
          We were looking for a better way to view ‘management metrics’ like
          MRR, ARR, Churn, ARPU, etc and track overall bookings and revenue
          growth. I had built a somewhat complicated Excel spreadsheet based on
          these essential markers, but it was nearly impossible to keep it
          accurate and up to date and to trust the information because it
          required constant manual updates. Probe provided a more accurate and
          real-time solution to visualize and access data points for our billing
          and revenue because they sync their software automatically with
          Stripe, which we use as our billing system.
        </p>
      </div>
      <Quote text="Previously we relied on Excel spreadsheets which is undesirable because it requires manual updating, is prone to human error, and may not include the latest information."></Quote>
      <Quote text="Anything that requires manual upkeep will ultimately fail or contain inaccurate information."></Quote>
      <div className="py-5">
        <h2>What was important for you when trying to solve this problem?</h2>
        <p>
          I wanted to be able to trust the information, cut down on the time I
          spent gathering and cleaning the data, and had a way to visualize the
          information instead of creating more rudimentary graphs in Excel as a
          way to visualize the data.
        </p>
      </div>
      <StarDivider />
      <div className="py-5">
        <h2>What did the implementation process look like?</h2>
      </div>
      <Quote text="Implementation was super smooth. I downloaded information from our billing systems in Excel spreadsheets and they were able to quickly manipulate the data into Probe."></Quote>
      <div className="py-5">
        <p>
          They were able to take our historical data as well, so we could start
          the clock several years earlier, which is essential in tracking trends
          over time. This was particularly critical because at one point we were
          maintaining two billing systems. There is no way Stripe would have
          been able to incorporate our historical information to get the
          complete picture that we needed.
        </p>
      </div>
      <StarDivider />
      <div className="py-5">
        <h2>
          How did it change after adopting Probe? Did it become easier? Faster?
          Do you have any numbers to back this up?
        </h2>
        <p>
          Probe was able to bifurcate our enterprise and self-serve revenue
          models. This was extremely helpful as we pursued a product-led growth
          strategy. We are able to see the growth comprehensively, as well as by
          different revenue streams. I was also able to download the data points
          when I needed to analyze the data further, which is something I have
          done as part of our quarterly board meetings.
        </p>
      </div>
      <DotsDivider />
      <div className="py-5">
        <p>
          I am able to review the data immediately and trust its accuracy. It
          would have taken me hours previously to confirm the accuracy of our
          data, not to mention the time it would take to build graphs and
          visualizations to present to the executive team. This would have
          became increasingly problematic, and required additional labor, as we
          scale the business further. Instead we are able to rely on technology
          instead of adding additional manpower.
        </p>
      </div>
      <DotsDivider />
      <div className="py-5">
        <p>
          There have been multiple times when I was asked a question about
          revenue by the executive team during a meeting that I was able to
          answer immediately by logging into Probe. If we did not have this
          tool, I would not be able to respond so quickly on the fly. It made me
          feel more in control of the numbers and gave me immediate credibility
          with the team.
        </p>
      </div>
      <StarDivider />
      <div className="py-5">
        <h2>How often do you interact with Probe?</h2>
        <p>
          I definitely use Probe on a weekly basis. It has all the hallmarks of
          a great company – synthesizes complex information into simple and
          beautiful design.
        </p>
      </div>
      <SliderDivider />
      <div className="pb-5"></div>
      <OtherCaseStudies names={['callpage', 'edrone', 'devskiller']} />
    </CaseStudyLayout>
  )
}

export default Castle
